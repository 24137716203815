import { styleForLabel } from 'components/Typography';
import styled from 'styled-components';
import { accessibilityFocus } from 'theme/styles';
import { MultiSelectCheckboxProps } from './MultiSelectCheckbox';
import { Icon } from 'components/Checkbox/Checkbox.styles';

export const SingleFilterCheckbox = styled.label<MultiSelectCheckboxProps>`
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	width: 100%;
	${styleForLabel}
	padding: 12px;
	margin: 0;
	max-height: 67px;

	span {
		color: ${({ theme }) => theme.colors.black};
		text-decoration: ${({ checked }) => checked && 'underline'};
	}

	&:hover {
		transition: background-color 100ms ease;
		background-color: ${(props) => props.theme.colors.blue2}a0;
		.name {
			text-decoration: underline;
		}
	}
	&:focus-within {
		background-color: ${(props) => props.theme.colors.blue2}a0;
		outline: 1px solid ${(props) => props.theme.colors.primary};
		outline-offset: -1px;
		.name {
			text-decoration: underline;
		}
	}
	&.first:focus-within {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	&.last:focus-within {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	background-color: ${({ checked, theme }) =>
		checked ? `${theme.colors.blue2}a0` : 'white'};
`;

export const StyledCheckbox = styled.div<MultiSelectCheckboxProps>`
	flex: 0 0 auto;
	width: 20px;
	height: 20px;
	border: none;
	background-color: transparent;

	${Icon} {
		line-height: 1rem;
		visibility: ${(props) =>
			props.checked && !props.disabled ? 'visible' : 'hidden'};
	}
`;
