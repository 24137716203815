import styled from 'styled-components';
import { px2rem } from 'theme/utils';

export const FilterModalContainer = styled.div<{ state: string }>`
	position: fixed;
	bottom: ${({ state }) => (state === 'entered' ? '0' : '-100%')};
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.theme.colors.white};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: bottom 0.3s ease-in-out;
	z-index: 1000;
`;

export const FilterModalContent = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	padding-bottom: ${(props) => props.theme.spacing.space3}rem;
	overflow: auto;
	flex-grow: 1;
`;

export const FilterModalHeader = styled.div`
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	padding: ${px2rem(22)} ${(props) => props.theme.spacing.space2}rem;
	width: 100%;
	height: ${px2rem(100)};
	position: sticky;
	display: flex;
	justify-content: space-between;
	align-items: start;
	top: 0;
	z-index: 1;
	h4 {
		color: ${(props) => props.theme.colors.white};
		font-weight: 700;
		margin: 0;
		font-size: ${px2rem(24)};
		line-height: ${px2rem(33)};
	}
	span {
		right: -10px;
		position: relative;
	}
	Button {
		color: ${(props) => props.theme.colors.white};
	}
`;

export const FilterModalFooter = styled.div<{ filterFlag: boolean }>`
	background-color: ${(props) => props.theme.colors.white};
	color: ${(props) => props.theme.colors.white};
	padding: ${px2rem(20)} ${px2rem(16)};
	min-height: ${px2rem(100)};
	width: 100%;
	position: sticky;
	bottom: 0;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
	button {
		display: flex;
		justify-content: center;
	}
	.mobile-clearall-btn {
		${({ filterFlag, theme }) =>
			!filterFlag &&
			`
      background-color: #DDDDDD;
      border: 1px solid ${theme.colors.grey1};
      color: ${theme.colors.grey1};
      cursor: not-allowed;
    `}
	}
`;
