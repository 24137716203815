/**
 * ListingPageGrantsHeader - styles
 */

import { getThemeBackground, getThemeTextColor } from 'pages/sharedModelTypes';
import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { ListingPageGrantsHeaderProps } from './ListingPageGrantsHeader';
import { zeroMarginPadding } from 'components/Typography';

export const Div = styled.div<ListingPageGrantsHeaderProps>`
	background-color: ${(p) => getThemeBackground(p.theme, p.themeTop)};
	display: flex;

	min-height: 400px;
	padding: ${(props) => props.theme.spacing.space2}rem;
	${mediaQueries.phone} {
		${(props) => props.theme.spacing.space2}rem;
	}

	.w-list-page-grants-header {
		width: 1100px;
	}

	.heading-mt {
		margin-top: 64px;
	}

	.preamble-max-width {
		max-width: 700px;
	}
`;

export const Preamble = styled.p<ListingPageGrantsHeaderProps>`
	${zeroMarginPadding};
	${(props) => props.theme.typography.style7.getStyle()};
	color: ${(p) => getThemeTextColor(p.theme, p.themeTop)};
`;

export const PublicationLink = styled.p<ListingPageGrantsHeaderProps>`
	min-height: 220px;
	display: flex;
	flex-direction: column;
	${(props) => props.theme.typography.style8.getStyle()};
	color: ${(p) => getThemeTextColor(p.theme, p.themeTop)};

	a {
		display: inline-block;
		color: ${(p) => getThemeTextColor(p.theme, p.themeTop)} !important;
		${(props) => props.theme.typography.style8.getStyle()};
		margin-top: ${(p) => p.theme.spacing.space2}rem;
	}
`;
