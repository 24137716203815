import { ImageFragmentModel } from 'types/fragments';

export interface SearchListingModel {
	query: string;
	page: number;
	size: number;
	numberOfHits: number;
	totalPages: number;
	totalHits: number;
	results: Result[];
	facetModels: FacetModel[];
	sortModel: SortModel;
}

export interface FacetModel {
	name: string;
	key: string;
	facetType: FacetTypeOf;
	facetOptions: FacetOption[];
}

export enum FacetTypeOf {
	singleSelect = 'singleSelect',
	multiSelect = 'multiSelect',
	dateSelect = 'dateSelect',
}

export interface FacetOption {
	name: string;
	key: string;
	hits: number;
	helpText?: string;
	selected: boolean;
	timeInterval?: TimeInterval;
}

export interface TimeInterval {
	fromLabel: string;
	toLabel: string;
	btnResetLabel: string;
	btnAddLabel: string;
	selectYearLabel: string;
	selectableYears: SelectableYear[];
}

export interface SelectableYear {
	year: number;
	selected: boolean;
	hits: number;
}

export interface Result {
	documentId: string;
	score: number;
	url: string;
	heading: string;
	excerpt: string;
	decal: string | null;
	image?: ImageFragmentModel;
}

export interface SortModel {
	name: string;
	key: string;
	sortOptionModels: SortOptionModel[];
}

export interface SortOptionModel {
	name: string;
	key: string;
	selected: boolean;
}
