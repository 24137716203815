/**
 * SearchByListngPage
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSearchResult, selectLocalization } from 'store/modules/model';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { ApiPath } from 'api/epiApi';
import { buildQueryString, debounce } from 'utils/helper-utils';
import { ButtonVariant } from 'pages/sharedModelTypes';
import {
	FacetModel,
	FacetOption,
	FacetTypeOf,
	SearchListingModel,
} from './SearchByListngPage.model';
import {
	PillResetButton,
	PillsBlock,
	ResultMessageHelper,
	SearchInputWrapper,
	SearchOverlappBlock,
	StyledSpan,
} from './SearchByListngPage.styles';
import FilterDropdown from 'components/FilterDropdown';
import Button from 'components/Button';
import Pills from 'components/Pills';
import Autocomplete from 'components/Autocomplete';
import { PillItem } from 'components/Pills/Pills';
import { IconCross } from 'components/Boilerplate/Icon/IconCollection';

interface UpdatedFacets {
	facets: {
		[key: string]: string[];
	};
}

interface FacetsTypes {
	[key: string]: string[];
}

export type queryParameters = {
	id: string;
	p: number | null;
	s: number;
	lang: string;
	q?: string;
	facets: FacetsTypes;
	sortBy: string | null;
};

interface Props {
	/** Description of this property */
	filterLabel: string;
	searchActionText: string;
	searchTopic: string;
	searchResultLabel: string;
	searchResultLabelNoMatchesHint: string;
	searchResultLabelNoMatches: string;

	searchModel: SearchListingModel;
	url?: string;
	rootPageId: string;
	clearAllFiltersText: string;
	pageChanged?: boolean;
	modelType: string;
	searchApi: string;
	autoCompleteApi: string;
	/**use this flag to hide/show clear all link */
	showClearAll?: boolean;
	/**use this flag to hide/show no result feedback under search field */
	filterEnabled?: boolean;
}

/** Main description for this component. */
const SearchByListngPage: React.FC<Props> = ({
	filterLabel,
	searchActionText,
	searchTopic,
	searchResultLabel,
	searchResultLabelNoMatchesHint,
	searchResultLabelNoMatches,
	searchModel,
	url,
	rootPageId,
	pageChanged = false,
	clearAllFiltersText,
	modelType,
	searchApi,
	autoCompleteApi,
	showClearAll = false,
	filterEnabled = false,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const localization = useSelector(selectLocalization);
	const isNarrowDisplay = useMediaQuery({
		minWidth: breakpointsNumber.phone,
		maxWidth: breakpointsNumber.tablet - 1,
	});
	const searchRef = useRef<HTMLInputElement | null>(null);
	const [userQuery, setUserQuery] = useState<string>('');
	const [pillItem, setPillItems] = useState<PillItem[]>([]);
	const [showAutocomplete, setShowAutocomplete] = useState(true);

	// destrcture seachModel
	const { query, page, size, facetModels, sortModel } = searchModel;

	const getInitialParameters = (): queryParameters => {
		return {
			q: query ?? '',
			p: page,
			s: size,
			id: rootPageId,
			lang: localization ? localization?.culture : 'sv',
			facets: {},
			sortBy: null,
		};
	};
	const [searchState, setSearchState] = useState<queryParameters>(
		getInitialParameters()
	);

	useEffect(() => {
		handleURLParameters(history.location);
	}, []);

	useEffect(() => {
		const handlePopState = (event: any) => {
			const newSearchState = {
				...searchState,
				facets: {},
				q: '',
				sortBy: null,
				page: 1,
			};
			const currentURL = window.location.pathname;
			setSearchState(newSearchState);
			setPillItems([]);
			executeSearch(newSearchState, currentURL);
		};

		window.addEventListener('popstate', handlePopState);

		// Cleanup the event listener when the component unmounts
		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [history]);

	useEffect(() => {
		if (pageChanged) {
			const newSearchState = {
				...searchState,
				p: searchModel.page,
			};
			executeSearch(newSearchState);
		}
	}, [pageChanged]);

	useEffect(() => {
		if (showClearAll) {
			resetAll();
		}
	}, [showClearAll]);

	useEffect(() => {
		updatedPills();
	}, [facetModels]);

	/** update date select pills */
	const updatedDateSelectPills = (
		TypeFilter: string,
		facetOptions: FacetOption[],
		existingPill: Set<string>,
		newPillItems: PillItem[]
	) => {
		// Remove all pills associated with the current TypeFilter if facetType is dateSelect or singleSelect
		newPillItems = newPillItems.filter(
			(pill) => pill.TypeFilter !== TypeFilter
		);
		existingPill.clear(); // Reset existing labels for this TypeFilter

		facetOptions.forEach((option) => {
			if (option.key === 'selectableYears') {
				const { timeInterval } = option;
				const selectedYears = timeInterval?.selectableYears.filter(
					(year) => year.selected
				);

				if (selectedYears === undefined) return;
				if (selectedYears && selectedYears.length > 1) {
					const kPLabel = selectedYears[0]?.year.toString();
					const minYear = Math.min(...selectedYears.map((year) => year.year));
					const maxYear = Math.max(...selectedYears.map((year) => year.year));

					// Create a single pill with the combined years
					newPillItems.push({
						label: `${minYear}-${maxYear}`,
						TypeFilter,
						keyPill: `${minYear}-${maxYear}`,
					});
					existingPill.add(`${minYear}-${maxYear}`);
				} else if (selectedYears && selectedYears.length === 1) {
					const kPLabel = selectedYears[0]?.year.toString();
					// Create a pill with that single year
					newPillItems.push({
						label: kPLabel,
						TypeFilter,
						keyPill: kPLabel,
					});
					existingPill.add(kPLabel);
				} else if (
					selectedYears !== undefined &&
					selectedYears.length > 0 &&
					!selectedYears[0].selected &&
					existingPill.has(selectedYears[0]?.year.toString())
				) {
					const kPLabel = selectedYears[0]?.year.toString();
					// If no years are selected, remove any existing pills for this facet
					const index = newPillItems.findIndex(
						(pill) => pill.keyPill === kPLabel
					);
					if (index > -1) {
						newPillItems.splice(index, 1);
						existingPill.delete(kPLabel);
					}
				}
			} else {
				const { name: optionName, selected, key: keyPill } = option;

				if (selected && !existingPill.has(keyPill)) {
					newPillItems.push({
						label: optionName,
						TypeFilter,
						keyPill: keyPill,
					});
					existingPill.add(keyPill);
				} else if (!selected && existingPill.has(keyPill)) {
					const index = newPillItems.findIndex(
						(pill) => pill.keyPill === keyPill
					);
					if (index > -1) {
						newPillItems.splice(index, 1);
						existingPill.delete(keyPill);
					}
				}
			}
		});
		return newPillItems;
	};

	/* get pill if exist a selected filter */
	const updatedPills = () => {
		if (!facetModels) return;

		const existingPill = new Set(pillItem.map((item) => item.keyPill));
		let newPillItems = [...pillItem];

		facetModels.forEach((item) => {
			const { key: TypeFilter, facetType, facetOptions } = item;
			if (facetType === 'dateSelect') {
				newPillItems = updatedDateSelectPills(
					TypeFilter,
					facetOptions,
					existingPill,
					newPillItems
				);
			}

			if (facetType === 'singleSelect') {
				// Remove all pills associated with the current TypeFilter if facetType is dateSelect or singleSelect
				newPillItems = newPillItems.filter(
					(pill) => pill.TypeFilter !== TypeFilter
				);
				existingPill.clear(); // Reset existing labels for this TypeFilter
			}

			if (facetType !== 'dateSelect') {
				facetOptions.forEach((option) => {
					const { name: optionName, selected, key: keyPill } = option;

					if (selected && !existingPill.has(keyPill)) {
						newPillItems.push({
							label: optionName,
							TypeFilter,
							keyPill: option.key,
						});
						existingPill.add(keyPill);
					} else if (!selected && existingPill.has(keyPill)) {
						const index = newPillItems.findIndex(
							(pill) => pill.keyPill === keyPill
						);
						if (index > -1) {
							newPillItems.splice(index, 1);
							existingPill.delete(keyPill);
						}
					}
				});
			}
		});

		setPillItems(newPillItems);
	};

	const onPillRemove = (pill: PillItem, flag: boolean) => {
		const updatePills = pillItem.filter(
			(item) => item.keyPill !== pill.keyPill
		);
		const updatedFacets: UpdatedFacets = { facets: { ...searchState.facets } };
		if (pill) {
			if (updatedFacets.facets.hasOwnProperty(pill.TypeFilter)) {
				if (pill.TypeFilter === 'ReleaseDate') {
					// Remove 'F' and 'T' from the string and update the value in updatedFacets
					updatedFacets.facets[pill.TypeFilter] = updatedFacets.facets[
						pill.TypeFilter
					].map((date) => date.replace('F', '').replace('T', ''));
				}

				if (updatedFacets.facets[pill.TypeFilter].includes(pill.keyPill)) {
					updatedFacets.facets[pill.TypeFilter] = updatedFacets.facets[
						pill.TypeFilter
					].filter((opt) => opt !== pill.keyPill);
				}
			}
		}
		const newSearchState = {
			...searchState,
			...updatedFacets,
		};
		setPillItems(updatePills);
		setSearchState(newSearchState);
		// flag tell if make request update or not. If it comes from onFilterChanged no need
		if (flag) {
			debouncedExecuteSearch(newSearchState, '');
		}
	};

	const handleURLParameters = (location: any) => {
		if (location.search.length < 0) {
			return;
		}
		// Decode URL parameters
		const urlParams = new URLSearchParams(location.search);
		const facets = urlParams.get('facets');
		const query = urlParams.get('q');
		const sortBy = urlParams.get('sortBy');
		const page = urlParams.get('p');

		// Split facets
		let splitedFacets = facets?.split(';') || [];
		let ff: { [key: string]: string[] } = {};

		splitedFacets.forEach((facet) => {
			const [key, ...values] = facet.split(':');
			const decodedValues = values.map((value) => decodeURIComponent(value));

			if (!ff[key]) {
				ff[key] = [];
			}
			ff[key] = ff[key].concat(decodedValues);
		});

		// Update search bar if query exists
		setUserQuery(query ?? '');

		// Update newSearchState with URL params
		const newSearchState = {
			...searchState,
			facets: ff,
			q: query ?? '',
			sortBy: sortBy ?? searchState.sortBy,
			page: page ?? 1,
		};
		setSearchState(newSearchState);

		// need compare if newSearchState is same as const { query, page, size, facetModels } = searchModel;
		if (
			newSearchState.q !== searchModel.query ||
			newSearchState.page !== searchModel.page
		) {
			executeSearch(newSearchState);
		} else {
			const keys = Object.keys(newSearchState.facets);

			// contemplate facetModels
			searchModel.facetModels.forEach((facetModel) => {
				const facetKey = facetModel.key;
				if (keys.includes(facetKey)) {
					facetModel.facetOptions.forEach((facetOption) => {
						if (
							!facetOption.selected &&
							facetOption.key === newSearchState.facets[facetKey]?.[0]
						) {
							executeSearch(newSearchState);
						}
					});
				}
			});
		}
	};

	/* search actions */

	const handleUserQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserQuery(event.target.value);
		setShowAutocomplete(true);
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();

		if (userQuery.length > 0) {
			const params = {
				...searchState,
				q: userQuery,
				p: 1,
			};
			setSearchState(params);
			executeSearch(params);
		}
	};

	const resetSearch = () => {
		setUserQuery('');

		if (searchState.q) {
			const params = {
				...searchState,
				q: '',
				p: searchState.p ?? 1,
			};
			setSearchState(params);
			executeSearch(params);
		}
	};

	/* filters actions */

	const resetFilters = () => {
		setPillItems([]);
		const newSearchState = {
			...searchState,
			facets: {},
			sortBy: null,
		};

		setSearchState(newSearchState);
		executeSearch(newSearchState);
	};

	const resetAll = () => {
		setPillItems([]);
		setUserQuery('');
		const newSearchState = {
			...searchState,
			facets: {},
			sortBy: null,
			q: '',
			p: 1,
		};
		setSearchState(newSearchState);
		executeSearch(newSearchState);
	};

	const onFilterChanged = (filter: any, typeOfFacet: FacetTypeOf) => {
		const target = filter.target;
		const filterTypeOf = target.getAttribute('data-filter-typeof');
		const checkboxValue = target.value.trim();
		const pillName = target.name;
		const updatedFacets: UpdatedFacets = {
			facets: { ...searchState.facets },
		};

		if (updatedFacets.facets.hasOwnProperty(filterTypeOf)) {
			if (updatedFacets.facets[filterTypeOf].includes(checkboxValue)) {
				// if true > user click unchecked existing opt & remove pill
				updatedFacets.facets[filterTypeOf] = updatedFacets.facets[
					filterTypeOf
				].filter((opt) => opt !== checkboxValue);
				onPillRemove(
					{ label: pillName, TypeFilter: filterTypeOf, keyPill: checkboxValue },
					false
				);
			} else {
				// add the opt to array
				if (typeOfFacet === FacetTypeOf.multiSelect) {
					updatedFacets.facets[filterTypeOf] = [
						...updatedFacets.facets[filterTypeOf],
						checkboxValue,
					];
				} else {
					updatedFacets.facets[filterTypeOf] = [checkboxValue];
				}
			}
		} else {
			// if not exist i create it
			updatedFacets.facets[filterTypeOf] = [checkboxValue];
		}

		const newSearchState = {
			...searchState,
			...updatedFacets,
			page: 1,
		};

		setSearchState(newSearchState);
		executeSearch(newSearchState);
	};

	const onDateSelectChanged = (
		range: { from: number; to: number },
		filterTypeOf: string
	) => {
		const updatedFacets: any = {
			facets: { ...searchState.facets },
		};

		const startYear = 'F';
		const endYear = 'T';

		// Build the facet string
		if (range.from && range.to) {
			updatedFacets.facets[filterTypeOf] = [
				startYear + range.from + '-' + endYear + range.to,
			];
		} else if (range.from) {
			updatedFacets.facets[filterTypeOf] = [startYear + range.from];
		} else if (range.to) {
			updatedFacets.facets[filterTypeOf] = [endYear + range.to];
		} else if (range.from === null && range.to === null) {
			updatedFacets.facets = {};
		}

		const newSearchState = {
			...searchState,
			...updatedFacets,
			page: 1,
		};

		setSearchState(newSearchState);
		executeSearch(newSearchState);
	};

	/**autocomplete submit function */
	const handleAutocompleteSubmit = (suggestion: string) => {
		if (suggestion.length > 0) {
			const params = {
				...searchState,
				q: suggestion,
				p: 1,
			};
			setSearchState(params);
			executeSearch(params);
			setUserQuery(suggestion);
		}
	};

	const executeSearch = (params: queryParameters, currentURL: string = '') => {
		const queryParams = {} as queryParameters;
		const searchURL = {} as queryParameters;

		queryParams.p = params.p;
		searchURL.p = params.p && params.p > 1 ? params.p : null;
		queryParams.s = params.s ? params.s : 12;
		queryParams.id = params.id;
		queryParams.lang = params.lang;

		if (params.q) {
			queryParams.q = params.q.trimStart();
			searchURL.q = queryParams.q;
		}

		/* special treatment for facets */
		const facetsArray = Object.entries(params.facets);

		const encodesFilters = facetsArray
			.map(([key, values]) => {
				if (values.length > 0) {
					const formattedValues = values.map((value) =>
						encodeURIComponent(value)
					);

					return `${key}:${formattedValues.join(':')}`;
				}
				return null;
			})
			.filter((filter) => filter !== null) // Remove null values
			.join(';');

		const filtersURL = encodesFilters !== '' ? `facets=${encodesFilters}` : '';

		/* build URL */
		const queryFormated = buildQueryString(searchURL);

		let queryURL = '';
		if (filtersURL.length > 0) {
			queryURL += filtersURL;
		}
		if (queryFormated.length > 0) {
			queryURL += (queryURL.length > 0 ? '&' : '') + queryFormated;
		}

		let URL = currentURL.length > 0 && currentURL !== url ? currentURL : url;
		history.push({ pathname: URL, search: queryURL });

		/* fetch results */
		const queryParamsURL =
			filtersURL.length > 0
				? filtersURL + '&' + buildQueryString(queryParams)
				: buildQueryString(queryParams);

		setShowAutocomplete(false);
		dispatch(
			fetchSearchResult(
				modelType,
				rootPageId,
				'searchResponse',
				'/',
				searchApi,
				queryParamsURL
			)
		);
	};

	/** debounce function to execute search after 350ms */
	const debouncedExecuteSearch = useCallback(
		debounce((params: queryParameters, currentURL: string) => {
			executeSearch(params, currentURL);
		}, 350),
		[]
	);
	/* 

	const debouncedSetPillItems = debounce(
		(updateFunction: (prevPillItems: any[]) => any[]) => {
			setPillItems(updateFunction);
		},
		150
	); */

	return (
		<SearchOverlappBlock>
			<form onSubmit={handleSubmit} role="search">
				{/* SEARCH */}
				<label className="as-h3" htmlFor="userListingInputQuery">
					{searchTopic}
				</label>
				<SearchInputWrapper>
					<div className="autocomplete-section">
						<input
							type="text"
							id="userListingInputQuery"
							placeholder={searchActionText}
							aria-label={searchActionText}
							maxLength={200}
							value={userQuery}
							ref={searchRef}
							onChange={(event) => handleUserQuery(event)}
							autoComplete="off"
						/>
						{showAutocomplete && (
							<Autocomplete
								query={userQuery}
								onSubmit={handleAutocompleteSubmit}
								currentState={searchState}
								autoCompleteApi={autoCompleteApi}
							/>
						)}
					</div>
					{userQuery.length >= 1 && (
						<div
							className="search-close-icon"
							onClick={resetSearch}
							tabIndex={0}
						>
							<IconCross
								width="24px"
								height="24px"
								aria-label="clean input field"
							/>
						</div>
					)}
					{!isNarrowDisplay && (
						<>
							<Button aria-label={searchActionText} type="submit">
								{searchActionText}
							</Button>
						</>
					)}
				</SearchInputWrapper>
				{query && (
					<>
						{searchResultLabel?.length && searchModel.numberOfHits > 0 ? (
							<ResultMessageHelper>
								<span>
									{searchResultLabel.split('{0}').map((part, index) => {
										return (
											<React.Fragment key={index}>
												{part}
												{index > 0 && <StyledSpan>"{query}"</StyledSpan>}
											</React.Fragment>
										);
									})}
								</span>
							</ResultMessageHelper>
						) : (
							!filterEnabled && (
								<ResultMessageHelper>
									<span className="span-flex">
										<span>
											{searchResultLabelNoMatches.replace('{0}', query)}
										</span>
										<span className="as-label">
											{searchResultLabelNoMatchesHint}
										</span>
									</span>
								</ResultMessageHelper>
							)
						)}
					</>
				)}
			</form>
			<div>
				{/* FILTERS */}
				<label className="as-h4" htmlFor="userListingFilter">
					{filterLabel}
				</label>

				<FilterDropdown
					facetModels={facetModels}
					onChange={onFilterChanged}
					onDateSelectChanged={onDateSelectChanged}
				/>
				<PillsBlock>
					{pillItem.length > 0 &&
						pillItem.map((pill, index) => {
							return (
								<Pills
									key={index}
									items={[pill]}
									onRemove={() => onPillRemove(pill, true)}
								/>
							);
						})}
					{pillItem.length > 1 && (
						<PillResetButton onClick={resetFilters}>
							<span>{clearAllFiltersText}</span>
						</PillResetButton>
					)}
				</PillsBlock>
			</div>
		</SearchOverlappBlock>
	);
};

export default SearchByListngPage;
